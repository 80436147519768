import omit from 'lodash/omit';
import store from '../../redux/store';
import { patchUser } from '../../redux/actions/users';
import { patchProfileRequirement } from '../../redux/actions/profile-completion';

/* @ngInject */
export default function LearnerProfileController(
  $scope,
  $stateParams,
  $location,
  LearnerProfileModel,
  LearnerProfileManager,
  Upload,
  CurrentUserManager,
  CurrentPermissionsManager,
  CurrentCourseManager,
  $uibModal,
  config,
  TimelinesManager,
  ReactTimelineService,
  nvUtil,
  _,
) {
  const vm = this;

  vm.config = config;
  vm.manager = LearnerProfileManager;
  vm.$stateParams = $stateParams;
  vm.form = null;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.course = null;
  vm.savePhoto = savePhoto;

  vm.editInfo = {
    isEditable: false,
    lastEditedField: '',
  };

  vm.defaultMin = 3;
  vm.minCompletedCourses = 6;
  vm.canUserEditBasicInfo = true;
  vm.shownCompletedCourses = { num: vm.minCompletedCourses };
  vm.shownTeams = { num: vm.defaultMin };
  vm.shownSubmissions = { num: vm.defaultMin };
  vm.shownCertificates = { num: vm.defaultMin };

  vm.privateSubmissionPlaceholderVisible = false;
  vm.readonlyWarningKey = '';
  vm.loggedInFromRussia = false;
  vm.getDisplayAccountLevelField = getDisplayAccountLevelField;

  /**
   * = True: when viewing team profile from current course page( from lecture page or user management )
   *
   * = False: when viewing learner profile for a course different from what is set in currentCourseManager,
   * we are disabling edit options, admin cog menu  any of the admin actions
   */
  vm.viewingFromCurrentCourse = CurrentCourseManager.course?.catalogId === vm.$stateParams.catalogId;

  initialize();

  function initialize() {
    let getProfilePromise;
    // Get Course or Novoed profile
    if (vm.$stateParams.catalogId) {
      getProfilePromise = vm.manager.getCourseProfile(vm.$stateParams.userId, vm.$stateParams.catalogId);
      CurrentUserManager.requestAndUpdateCourse(vm.$stateParams.catalogId, true).then(() => {
        vm.course = CurrentUserManager.getCurrentCourse();
      });
    } else {
      getProfilePromise = vm.manager.getProfile(vm.$stateParams.userId);
    }

    getProfilePromise.then(() => {
      vm.canEdit = vm.manager.isViewingSelfProfile;
      vm.editInfo.isEditable = vm.viewingFromCurrentCourse && vm.manager.isViewingSelfProfile;
    });

    if (CurrentUserManager.user?.loggedInFromRussia) {
      vm.readonlyWarningKey = 'PROFILE.RUSSIA_COMPLIANCE_WARNING';
      vm.loggedInFromRussia = true;
    }

    initializeSsoInfoEditPermission();
  }

  function getCurrentInstitution() {
    return CurrentUserManager.user?.institutions.find((institution) => institution.host === $location.host());
  }

  function initializeSsoInfoEditPermission() {
    const currentInstitution = getCurrentInstitution();

    if (currentInstitution) {
      vm.canUserEditBasicInfo = currentInstitution.enableBasicSsoInfoEdit;
    }
  }

  // Import from social media
  let effectiveHost = config.app.dummyHost;
  if (window.location.origin.includes('novoed.com') !== true) {
    effectiveHost = window.location.host;
  }
  // Import from social media
  const location = encodeURIComponent(`${config.app.protocol}://${effectiveHost}/#!${$location.path()}`);
  vm.linkedinUrl = `${config.app.protocol}://${effectiveHost}/omniauth_post?provider=linkedin&origin=${location}?import=linkedin`;

  // Profile photo upload
  function savePhoto(file) {
    let url;
    vm.savingPhoto = true;

    if (vm.$stateParams.catalogId) {
      url = `/${vm.$stateParams.catalogId}/update_profile_pic`;
    } else {
      url = '/update_profile_pic';
    }

    return Upload.upload({
      url,
      method: 'PUT',
      fields: {
        'user[user]': vm.manager.profileUser.id,
        picture: file,
      },
    }).then((response) => {
      // success
      displayEarnedPoints(response.data.result);
      const newProfilePicture = response.data.result.profilePicture;
      vm.manager.profileUser.profilePicture = newProfilePicture;
      CurrentUserManager.user.profilePicture = newProfilePicture;

      store.dispatch(patchUser({
        id: CurrentUserManager.user.id,
        profilePicture: newProfilePicture,
      }));

      vm.savingPhoto = false;
    }, (response) => {
      // error
      vm.savingPhoto = false;
    }, (evt) => {

    });
  }

  vm.save = function () {
    const payload = _.omit({
      ...vm.manager.editableData,
      profileResponse: {
        answers: vm.manager.editableData.profileDetails.map(
          (profileDetail) => _.omit({
            ...profileDetail,
            inputType: profileDetail.formType,
            profileSettingId: profileDetail.questionId,
          }, ['formType', 'questionId']),
        ),
      },
    }, ['profileDetails']);

    LearnerProfileModel.saveProfile(vm.$stateParams.catalogId, payload).then((result) => {
      displayEarnedPoints(result);

      _.extend(CurrentUserManager.user, vm.manager.editableData.user);

      /**
       * NOTE: Populating displayLocation because that's how backend retrieves
       * the location value
       */
      CurrentUserManager.user.displayLocation = vm.manager.editableData.user.location;

      const { location: displayLocation, firstName, lastName, ...restUser } = vm.manager.editableData.user;

      store.dispatch(patchUser({
        ...restUser,
        displayLocation,
        id: CurrentUserManager.user.id,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
      }));

      CurrentUserManager.user.__preprocess();
    });
  };

  vm.showPrivateSubmissions = function () {
    vm.manager.allSubmissions = vm.manager.submissions.concat(vm.manager.privateSubmissions);
    vm.shownSubmissions.num = vm.manager.allSubmissions.length;
    vm.privateSubmissionPlaceholderVisible = false;
  };

  vm.hidePrivateSubmissions = function () {
    vm.privateSubmissionPlaceholderVisible = false;
    vm.manager.allSubmissions = vm.manager.submissions;
  };

  vm.showPrivatePlaceholderCard = function () {
    vm.privateSubmissionPlaceholderVisible = true;
  };

  vm.sendMessage = function () {
    vm.conversation = {
      message: {
        body: '',
        subject: '',
      },
      recipients: [vm.manager.profileUser],
      recipientType: 'user',
    };

    $uibModal.open({
      templateUrl: 'learner_profiles/templates/send-message-modal.html',
      windowClass: 'send-message-modal',
      controller: 'SendMessageModalCtrl as vm',
      resolve: {
        conversation: vm.conversation,
      },
    });
  };


  vm.showdiscussionContributionModal = function () {
    $uibModal.open({
      templateUrl: 'learner_profiles/templates/discussion-contribution-modal.html',
      windowClass: 'contribution-modal',
      scope: $scope,
    });
  };

  vm.canViewCertificate = function () {
    return vm.manager.profileUser.id === vm.CurrentUserManager.user.id || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant();
  };

  /**
     * We can open learner profile modal on top a different course or without a course
     * We are disabling profile completion questions if currentCourseManager is not the
     * same as the catalogId in the StateParams
     */
  vm.isNovoedQuestionRequired = function (questionIdentifier) {
    if (!vm.viewingFromCurrentCourse) {
      return false;
    }
    const { profileRequirements } = (CurrentCourseManager?.course || {});

    if (profileRequirements) {
      const novoedQuestionsList = profileRequirements.reduce((questions, { novoedQuestions }) => [...new Set(questions.concat(novoedQuestions))], []);
      return novoedQuestionsList?.includes(questionIdentifier);
    }

    return false;
  };

  function displayEarnedPoints(result) {
    const {
      profileRequirements,
      leaderboardRank,
      leaderboardPoints,
    } = result;

    /**
     * We get progress, pointsReceived, leaderboardRank & leaderboardPoints
     * properties if there's a profile completion lecture component added in
     * the current lecture page, these properties belong to lecture component
     * profile requirement entity.
     * NOTE: Only looking for progress because the rest of the properties are
     * always there if at least progress is.
     */
    if (_.has(result, 'profileRequirements')) {
      const state = store.getState();
      const stateProfileComponents = state.models.profileRequirements;

      let sumOfTotalPointsReceived = 0;
      let updatedInCourse = false;
      const currentLectureId = state?.app?.lecturePage?.currentLectureId;

      if (currentLectureId) {
        ReactTimelineService.updateTimeline(currentLectureId);
      }

      // updating the store with latest progress for all the profile components
      profileRequirements.filter(obj => Object.keys(obj).length !== 0).forEach(item => {
        const { id, progress, pointsReceived } = item;

        sumOfTotalPointsReceived += pointsReceived;
        let patch = {};
        if (progress === 'completed' && pointsReceived === 0) {
          // We don't need to pass pointsReceived in this case, as this will set
          // the points to 0 when patching. The pointsReceived will be send from
          // backend when the activity is completed in this save, on subsequent
          // saves it will be returned as 0.
          patch = {
            progress,
          };
        } else {
          patch = {
            progress,
            pointsReceived,
          };
        }

        if (stateProfileComponents[id]) {
          store.dispatch(patchProfileRequirement({
            profileRequirementId: id,
            profileRequirementPatch: patch,
          }));
          updatedInCourse = true;
        }
      });

      if (sumOfTotalPointsReceived > 0) {
        const {
          priorLeaderboardRank,
          leaderboardRank: fakePriorLeaderboardRank,
        } = CurrentCourseManager.course.profileRequirement;

        $uibModal.open({
          windowClass: 'points-modal',
          controller: 'PointsModalCtrl as vm',
          templateUrl: 'shared/templates/points-modal.html',
          resolve: {
            extras: null,
            pointsReceived: sumOfTotalPointsReceived,
            leaderboardRank,
            leaderboardPoints,
            priorLeaderboardRank:
              updatedInCourse ? priorLeaderboardRank : fakePriorLeaderboardRank,
          },
        });

        if (!updatedInCourse) {
          /**
           * If we have pointsReceived retrieved, this means there must be a
           * profileRequirement set in current course because that's a
           * specification in save_profile.json endpoint.
           * Points data will be retrieved only if the course is already
           * released.
           */
          CurrentCourseManager.course.extendProfileRequirement({
            pointsReceived: sumOfTotalPointsReceived,
            leaderboardRank,
            leaderboardPoints,
            priorLeaderboardRank: fakePriorLeaderboardRank,
          });
        }
      }
    }
  }

  function getDisplayAccountLevelField(fieldName) {
    const { demandedUserId } = vm.manager;
    const { user: currentUser } = CurrentUserManager;
    const isSelfUser = currentUser.id === demandedUserId;
    const answer = vm.manager.editableData.user[fieldName];
    const { isInstitutionalAdmin } = getCurrentInstitution();
    const isCurrentUserOrgAdmin = isInstitutionalAdmin || currentUser.admin;

    const {
      isHidden = false,
    } = vm.manager.accountLevelOrgSettings?.[fieldName] || {};

    if (isSelfUser) {
      if (isCurrentUserOrgAdmin) {
        return true;
      }

      return !isHidden;
    }

    if (isCurrentUserOrgAdmin) {
      return !!answer;
    }

    return answer && !isHidden;
  }
}
